export class Customer {
  uid!: number;
  authorised!: boolean;
  gid!: number;
  phone!: string;
  address_street!: string;
  address_build!: string;
  address_flat!: string;

    constructor(uid: number, authorised: boolean, gid: number, phone: string,
                address_street: string, address_build: string, address_flat: string) {
        this.uid = uid;
        this.authorised = authorised;
        this.gid = gid;
        this.address_street = address_street;
        this.address_build = address_build;
        this.address_flat = address_flat;
        this.phone = phone;
    }
}

export class DetailedCustomer extends Customer {
    group_name!: string;

    constructor(uid: number, authorised: boolean, gid: number, phone: string,
                address_street: string, address_build: string, address_flat: string, group_name: string) {
        super(uid, authorised, gid, phone, address_street, address_build, address_flat);
        this.group_name = group_name;
    }
}

interface AbstractCustomers {
  customers: Customer[]
}

export type Customers = AbstractCustomers;
