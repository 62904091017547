





































import { Component, Vue } from 'vue-property-decorator';
import Header from '@/types/tables';
import { getCustomers } from '@/api';
import { Customer, DetailedCustomer } from '@/types/customers';
import { abillsModule } from '@/store';

@Component({name: 'CustomersList'})
export default class CustomersList extends Vue {
    search = '';
    headers: Header[] = [
        {text: 'uid', value: 'uid'},
        {text: 'Авторизован', value: 'authorised'},
        {text: 'Улица', value: 'address_street'},
        {text: 'Дом', value: 'address_build'},
        {text: 'Квартира', value: 'address_flat'},
        {text: 'Группа', value: 'group_name'},
        {text: 'Телефон', value: 'phone'},
    ];
    customers: Customer[] = [];
    loading = false;

    get groups(): Record<number, string> {
        return abillsModule.groups.reduce((acc, cur) => ({...acc, [cur.gid]: cur.name}), {});
    }

    get detailedCustomers() {
        return this.customers.map(c => new DetailedCustomer(
            c.uid,
            c.authorised,
            c.gid,
            c.phone,
            c.address_street,
            c.address_build,
            c.address_flat,
            this.groups[c.gid],
        ));
    }

    async created() {
        await this.loadCustomers();
        await abillsModule.fetch();
    }

    async loadCustomers() {
        this.loading = true;

        try {
            //this.headers = statistic.headers.map(header => ({ text: header, sortable: false }));
            //this.statistic = statistic.rows;
            const customers = await getCustomers();
            this.customers = customers.customers;
        } catch (e) {

        } finally {
            this.loading = false;
        }
    }
}
